import { useEffect, useRef, useState } from 'react';
import styles from './Notification.module.scss';
import Icon from '@/components/icon/Icon';
import { TYPE_ERROR, TYPE_FAVORITE, TYPE_SMOKE, TYPE_SUCCESS } from '@/src/models/Notification';
import useNotifications from '@/src/hooks/useNotifications';
import keyEnterCheck from '@/src/helpers/MiscHelper';

export default function Notification({
    id,
    title,
    message,
    subMessage,
    type = TYPE_SUCCESS,
    onCloseClick,
    onContentClick,
    showTimeout,
    style,
    closeButton,
}) {
    const [isVisible, setIsVisible] = useState(true);
    const { removeNotification } = useNotifications();
    const onCloseTimeoutRef = useRef(null);

    useEffect(() => {
        onCloseTimeoutRef.current = setTimeout(onClose, showTimeout);
    }, []);

    const onClose = () => {
        clearTimeout(onCloseTimeoutRef.current);
        setIsVisible(false);
        removeNotification(id);
        if (onCloseClick) {
            onCloseClick();
        }
    };

    const onClick = () => {
        if (onContentClick) {
            onContentClick();
        }
    };

    let icon;
    let notificationClass = '';
    let delimiter = '';
    switch (type) {
        case TYPE_ERROR:
            notificationClass = ' notification--error';
            icon = <Icon id={'warning'} modifier={'notification__icon'} />;
            break;
        case TYPE_FAVORITE:
            notificationClass = ' notification--favorites';
            icon = <Icon id={'love'} modifier={'notification__icon'} />;
            break;
        case TYPE_SMOKE:
            notificationClass = ' notification--smoke-free';
            icon = <img src="/assets/img/cigarette-free.svg" alt="dont-smoke" />;
            delimiter = <div className="notification__delimiter"></div>;
            break;
        case TYPE_SUCCESS:
            icon = <Icon id={'accept'} modifier={'notification__icon'} />;
            break;
        default:
            icon = null;
    }

    return (
        <div
            className={
                styles['notification'] +
                notificationClass +
                (isVisible ? ' notification--show' : ' notification--close')
            }
            style={style}
        >
            {closeButton && (
                <button className="notification__close" type="button" onClick={onClose}>
                    <span className="h-hidden--text">Закрыть модальное окно</span>
                    <Icon id={'close'} modifier={'modal__close-icon'} />
                </button>
            )}
            <div className={styles['notification__content']} onClick={onClick} onKeyDown={keyEnterCheck(onClick)}>
                {icon && <span className="notification__icon">{icon}</span>}
                {delimiter}
                <div className="notification__info">
                    <h4 className="notification__title">{title}</h4>
                    {message && <p className="notification__description">{message}</p>}
                    {subMessage && <p className="notification__description">{subMessage}</p>}
                </div>
            </div>
        </div>
    );
}
