import { useContext, useEffect, useState } from 'react';
import Notification from '@/components/notification/Notification';
import { LOCAL_STORAGE_KEY } from '@/src/contexts/app/reducers/notificationReducer';
import AppContext from '@/src/contexts/app/state/AppContext';

export default function Notifications() {
    const {
        notifications: { list, dispatch },
    } = useContext(AppContext);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        if (item) {
            dispatch({ body: item });
        }
    }, []);

    useEffect(() => {
        if (list) {
            setNotifications(list);
        }
    }, [list]);

    return (
        notifications &&
        notifications.length > 0 && (
            <div className="notifications" style={{ zIndex: 1000 }}>
                {notifications.map(
                    (notification) => notification && <Notification key={notification.id} {...notification} />
                )}
            </div>
        )
    );
}
